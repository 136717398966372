import React, { useState, useEffect, useContext, useMemo } from 'react'
import styled from 'styled-components'
import Header from './Header'
import Sidenav, { sidenavWidth } from './Sidenav'
import { appContext } from '../shared/contexts'
import { headerHeight } from '../shared/elements'
import Signin from '../signin'
import { fetchCurrentUser } from '../../services/api'
import {
  checkLogin,
  setCurrentUser,
  setCurrentWebsite,
  getCurrentWebsite,
  setCurrentStatus,
} from '../../services/auth'
import * as paths from '../../utils/paths'
import { getCurrentCurrency } from '../../utils/currencies'

import '@stripe/stripe-js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../static/stylesheets/bundle.scss'

const Main = styled.main`
  position: relative;
  margin-top: ${(props) => (props.header ? headerHeight : 0)}px;
  margin-left: ${(props) => (props.sidenav ? sidenavWidth : 0)}px;
`

export default function Layout(props) {
  const [isAuthenticated, setAuthenticated] = useState(null)
  const [currentUserLoaded, setCurrentUserLoaded] = useState(false)

  const { location } = useContext(appContext)

  useEffect(() => {
    const authenticated = checkLogin()
    setAuthenticated(authenticated)

    if (authenticated) {
      fetchCurrentUser({ currency: getCurrentCurrency() }).then((response) => {
        if (response?.data?.user) {
          const user = response.data?.user
          user.advertiser = response.data?.advertiser
          user.websites = response.data?.websites || []
          user.currencyRates = response.data?.currencyRates || {}
          setCurrentUser(user)

          const status = response.data?.status
          setCurrentStatus(status)

          const currentWebsite = getCurrentWebsite()
          if (!currentWebsite || !user.websites.includes(currentWebsite)) {
            setCurrentWebsite(user.websites[0])
          }

          setCurrentUserLoaded(true)
        } else {
          setCurrentUser(null)
        }
      })
    }
  }, [isAuthenticated])

  const showSidenav = useMemo(
    () =>
      !paths.compare(location?.pathname, paths.newPublisherPath()) &&
      !paths.compare(location?.pathname, paths.newPublisherContractPath('*')),
    [location]
  )

  if (isAuthenticated === null) {
    return null
  }

  if (!isAuthenticated) {
    return <Signin setAuthenticated={setAuthenticated} />
  }

  if (!currentUserLoaded) {
    return null
  }

  return (
    <div>
      <Header />
      {showSidenav && <Sidenav />}
      <Main header={true} sidenav={showSidenav}>
        {props.children}
      </Main>
    </div>
  )
}
