import React from 'react'
import SVG from 'react-inlinesvg'
import { HeaderContainer, Dropdown, HeaderHelp } from '../shared/elements'
import { logoutPath } from '../../utils/paths'
import { getCurrentStatus } from '../../services/auth'

export default function Header(props) {
  const status = getCurrentStatus()

  return (
    <header>
      <HeaderContainer>
        <div className="logo">
          <SVG src="/images/logo-light.svg" width={180} />
        </div>
        <div className="control">
          <HeaderHelp />

          <Dropdown
            buttonClassName="control-menu"
            button={
              <>
                <SVG src="/images/icons/connection.svg" width={20} />
                <span>Connection</span>
                <SVG
                  src="/images/icons/arrow-down.svg"
                  className="arrow"
                  width={16}
                />
              </>
            }
          >
            <>
              <li>
                <div className="connection-ring active">Script installed</div>
              </li>
              <li>
                <div
                  className={`connection-ring ${
                    status?.trackingImplemented ? 'active' : ''
                  }`}
                >
                  Server-to-server installed
                </div>
              </li>
              <li>
                <div
                  className={`connection-ring ${
                    status?.productFeedAdded ? 'active' : ''
                  }`}
                >
                  Product feed added
                </div>
              </li>
              <li>
                <div
                  className={`connection-ring ${
                    status?.trackingImplemented ? 'active' : 'inactive'
                  }`}
                >
                  Running
                </div>
              </li>
            </>
          </Dropdown>

          <a href={logoutPath()} className="control-menu">
            <SVG src="/images/icons/logout.svg" width={20} />
            <span>Logout</span>
          </a>
        </div>
      </HeaderContainer>
    </header>
  )
}
